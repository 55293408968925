import router from "./router";
import store from "./store";
import { MessageBox } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import getPageTitle from "@/utils/get-page-title";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login", "/temperatureLargeScreen", "/bigScreen"]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);
  const hasToken = store.getters.token;
  if (hasToken) {
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else if (from.path == "/common/editorOnline") {
      MessageBox.confirm("离开当前页面未保存的内容将会丢失!", "确定离开", {
        confirmButtonText: "确定",
        type: "warning",
      }).then(() => {
        next();
      });
      NProgress.done();
    } else if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      const hasGetUserInfo = store.getters.userInfo.username;
      if (hasGetUserInfo) {
        next();
        // 查询菜单信息
      } else {
        store.dispatch("getUserInfo").then((res) => {
          store.dispatch("getRoutes").then((accessRoutes) => {
            console.log(res);
            // router.addRoutes(accessRoutes); // 动态添加可访问路由表
            next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
          });
        });
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
